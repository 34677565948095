<template>
  <div class="Member">
    <Row class="px-2">
      <i-col span="24">
        <i-col span="8">
          <Form :model="surveyInfo" :label-width="100">
            <Row :gutter="32">
              <i-col span="24">
                <FormItem label="问卷名称" label-position="top">
                  <i-input v-model="surveyInfo.surveyName" size="large" placeholder="请输入问卷名称..."></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="问卷指导语" label-position="top">
                  <i-input
                    v-model="surveyInfo.surveyHint"
                    maxlength="250"
                    show-word-limit
                    type="textarea"
                    :rows="8"
                    placeholder="请输入问卷指导语..."
                  ></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="问卷说明" label-position="top">
                  <editor-bar v-model="surveyInfo.surveyDesc" :isClear="isClear" @change="change"></editor-bar>
                </FormItem>
              </i-col>
            </Row>
          </Form>
        </i-col>
        <i-col span="16" style="padding-left:10px;padding-bottom:10px;">
          <Form ref="formDynamic" :rule="rules" :model="formDynamic" :label-width="80">
            <Alert closable>
              <span slot="desc">
                提示:
                <br />1.拖动题目可调整题目顺序
                <br />2.拖动选项可调整选项顺序
              </span>
            </Alert>
            <vuedraggable v-model="formDynamic.topic" class="wrapper" @end="end">
              <div
                v-on:mouseover="changeActive($event)"
                v-on:mouseout="removeActive($event)"
                v-for="(item, index) in formDynamic.topic"
                :key="index"
              >
                <Row>
                  <FormItem
                    :label="`问题${index + 1}`"
                    label-position="top"
                    :prop="`topic.${index}.questionName`"
                    :rules="{required: true, message: '请填写题目内容', trigger: 'blur'}"
                    :show-message="false"
                  >
                    <i-col span="24">
                      <card>
                        <FormItem
                          :prop="`topic.${index}.type`"
                          :rules="{required: true,message: '请选择题目类型', trigger: 'blur'}"
                          :show-message="false"
                        >
                          <i-input
                            label="问题"
                            v-model="item.questionName"
                            placeholder="请输入题目内容"
                            size="large"
                          >
                            <Select v-model="item.type" slot="prepend" style="width: 80px">
                              <Option value="1">单选题</Option>
                              <Option value="2">多选题</Option>
                              <Option value="3">填空题</Option>
                            </Select>
                            <Button
                              slot="append"
                              icon="md-remove-circle"
                              @click="removeQuestion(index)"
                            ></Button>
                          </i-input>
                        </FormItem>
                        <vuedraggable v-model="item.answers" class="wrapper" @end="optionsend">
                          <FormItem
                            style="margin-top:10px;"
                            v-for="(opt, idx) in item.answers"
                            v-if="item.type == 1 || item.type == 2"
                            :key="idx"
                            :label="`选项${idx + 1}`"
                            :prop="`topic.${index}.answers.${idx}.value`"
                            :rules="{required: true,message: '请填写选项内容', trigger: 'blur'}"
                            :show-message="false"
                          >
                            <i-col span="18" style="display:-webkit-inline-box;">
                              <i-input size="large" v-model="opt.value" placeholder="请输入选项内容">
                                <Button
                                  slot="append"
                                  icon="md-remove-circle"
                                  @click="removeDomain(index,idx)"
                                ></Button>
                              </i-input>
                              <Checkbox
                                :value="false"
                                v-model="opt.ifChecked"
                                style="margin-left:5px;line-height:40px;"
                              >是否可填空</Checkbox>
                            </i-col>
                          </FormItem>
                          <FormItem
                            v-for="(opt, idx) in item.answers"
                            :key="idx"
                            v-if="item.type == 3"
                          >
                            <i-col span="18" style="display:-webkit-inline-box;">
                              <Checkbox
                                :value="false"
                                v-model="opt.ifChecked"
                                style="margin-left:5px;line-height:40px;"
                              >是否必填</Checkbox>
                            </i-col>
                          </FormItem>
                        </vuedraggable>
                        <FormItem
                          style="margin-top:10px;margin-left:80px;"
                          v-show="item.type == 1 || item.type == 2"
                        >
                          <i-col span="6">
                            <Button
                              v-show="item.type!=3"
                              type="dashed"
                              size="large"
                              long
                              @click="addDomain(index)"
                            >新增选项</Button>
                          </i-col>
                        </FormItem>
                      </card>
                    </i-col>
                  </FormItem>
                </Row>
              </div>
            </vuedraggable>
            <FormItem style="margin-top:5px;">
              <Row>
                <i-col span="6">
                  <Button type="dashed" size="large" long @click="addQuestion" icon="md-add">添加题目</Button>
                </i-col>
                <i-col span="4">
                  <Button
                    type="primary"
                    size="large"
                    style="width:120px"
                    @click="handleSubmit('formDynamic')"
                  >提交</Button>
                </i-col>
              </Row>
            </FormItem>
          </Form>
        </i-col>
      </i-col>
    </Row>
  </div>
</template>
<script>
import vuedraggable from "vuedraggable";
import EditorBar from "../components/wangeditor";
export default {
  name: "AddSurvey",
  components: {
    vuedraggable,
    EditorBar,
  },
  data() {
    return {
      isClear: false,
      detail: "",
      rules: {},
      timelist: [
        {
          value: "1",
          label: "09:00",
        },
        {
          value: "2",
          label: "10:00",
        },
        {
          value: "3",
          label: "11:00",
        },
        {
          value: "4",
          label: "12:00",
        },
        {
          value: "5",
          label: "13:00",
        },
        {
          value: "6",
          label: "14:00",
        },
        {
          value: "7",
          label: "15:00",
        },
        {
          value: "8",
          label: "16:00",
        },
        {
          value: "9",
          label: "17:00",
        },
        {
          value: "10",
          label: "18:00",
        },
      ],
      surveyInfo: {},
      index: 1,
      formDynamic: {
        topic: [],
      },
      theight: window.innerHeight - 175,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
  },
  methods: {
    change(val) {},
    changeActive(e) {
      e.currentTarget.className = "things active";
    },
    removeActive($event) {
      $event.currentTarget.className = "things";
    },
    optionsend(evt) {},
    end(evt) {},
    handleSubmit() {
      console.log(this.surveyInfo);
      if (this.surveyInfo.surveyName == undefined) {
        this.$Message.error("量表名称不得为空");
        return;
      }
      this.$refs.formDynamic.validate((valid) => {
        if (valid) {
          // 此处length==2是因为数组长度默认有[]字符，故为2
          if (JSON.stringify(this.formDynamic.topic).length == 2) {
            this.$Message.error("请创建题目");
            return;
          } else {
            this.surveyInfo.questions = JSON.stringify(this.formDynamic.topic);
            console.log(JSON.stringify(this.formDynamic.topic));
            let data = this.surveyInfo;
            this.$post("Survey/AddSurvey", data).then((res) => {
              if (res.data.code) {
                this.$Message.success({
                  content: "创建成功",
                  duration: 3,
                });
              } else {
                this.$Message.error({
                  content: "创建失败，请稍后重试",
                  duration: 2,
                });
              }
            });
          }
        } else {
          this.$Message.error("有未填写完毕的项");
        }
      });
    },
    removeDomain(index, idx) {
      // 删除选项
      this.formDynamic.topic[index].answers.splice(idx, 1);
    },
    removeQuestion(index) {
      //删除题目
      this.formDynamic.topic.splice(index, 1);
    },
    addDomain(index) {
      // 新增选项
      this.formDynamic.topic[index].answers.push({ value: "" });
    },
    addOthersDomain(index) {
      // 新增选项
      this.formDynamic.topic[index].answers.push({ value: "其他" });
    },
    addQuestion() {
      // 新增题目
      this.formDynamic.topic.push({
        type: "",
        questionName: "",
        answers: [{ value: "" }],
      });
    },
  },
};
</script>
<style>
.things {
}
.active {
  cursor: move;
}
</style>